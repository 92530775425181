@import "../styles/theme.less";
@import "../styles/buttons.less";
@import "../styles/cards.less";
@import "../styles/tables.less";
@import "../styles/forms.less";
@import "../styles/fonts.less";

html {
	height: 100%;
	width: 100%;
}

body {
	font-family: "Mulish", open-sans;
	width: 100%;
	height: 100%;
	background: white;
}

.ant-layout {
	background: #ffffff;
}

.form-input {
	/*padding: 12.5px 11px;*/
	padding: 9.5px 11px;
	background: white !important;
	border-radius: 5px;
}

.spinner-white {
	color: #ffffff;
}

::placeholder {
	font-size: 12px;
}

.bread-crumb-container {
	margin-top: -55px;
}

.ant-picker {
	width: 100%;
}

button.auth-buttons {
	width: 100% !important;
}

button.auth-buttons,
.header-btn,
.custom-modal-btn {
	background: #colors[green];
	outline: none;
	color: white;
	border: none;
	border-radius: 3px;
	height: 45px;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.header-btn {
	width: 15% !important;
	cursor: pointer;
}

.custom-modal-btn {
	width: 70% !important;
}

.notification-bar {
	background-color: #colors[lightblue];
}

@primary-color: #1DA57A;