@font-face {
	font-style: normal;
	src: url(../assets/fonts/Mulish-Black.ttf);
	src: url(../assets/fonts/Mulish-BlackItalic.ttf);
	src: url(../assets/fonts/Mulish-Bold.ttf);
	src: url(../assets/fonts/Mulish-BoldItalic.ttf);
	src: url(../assets/fonts/Mulish-ExtraBold.ttf);
	src: url(../assets/fonts/Mulish-ExtraBoldItalic.ttf);
	src: url(../assets/fonts/Mulish-ExtraLight.ttf);
	src: url(../assets/fonts/Mulish-ExtraLightItalic.ttf);
	src: url(../assets/fonts/Mulish-Italic.ttf);
	src: url(../assets/fonts/Mulish-Light.ttf);
	src: url(../assets/fonts/Mulish-LightItalic.ttf);
	src: url(../assets/fonts/Mulish-Medium.ttf);
	src: url(../assets/fonts/Mulish-MediumItalic.ttf);
	src: url(../assets/fonts/Mulish-Regular.ttf);
	src: url(../assets/fonts/Mulish-SemiBold.ttf);
	src: url(../assets/fonts/Mulish-SemiBoldItalic.ttf);
}
