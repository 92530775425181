@import './theme.less';

.staff-form-wrapper {
    width: 70%;
    margin: auto;

    .staff-form-content {
        width: 70%;
        margin: auto;
    }

    .title {
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        text-align: left;
        color: #colors[black];
    }
}

.staff-list {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 150%;
            color: #colors[black]
        }
    }
}

.staff-set-password {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
@primary-color: #1DA57A;