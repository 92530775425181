@import "./theme.less";

.he-button {
  background: #colors[green];
  box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
  border-radius: 3px;
  padding: 1.6em 2em 1.6em 2em;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  &:hover {
    background: #colors[green];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    align-items: center;
  }

  &:focus {
    background: #colors[green];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    align-items: center;
  }

  &:active {
    background: #colors[green];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    align-items: center;
  }

  .btn-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #colors[white];
  }
}

.copy-button {
  background: #colors[green];
  outline: none;
  height: 36px;
  padding: 0 28px;
  color: white;
  border: none;
  border-radius: 3px;
  line-height: 2;
}



.he-alt-button {
  background: #colors[white];
  box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
  border-radius: 3px;
  padding: 1.6em 2em 1.6em 2em;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  &:hover {
    background: #colors[white];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    align-items: center;
  }

  &:focus {
    background: #colors[white];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    align-items: center;
  }

  &:active {
    background: #colors[white];
    box-shadow: 0px 4px 10px rgba(48, 153, 75, 0.1);
    border-radius: 3px;
    align-items: center;
  }

  .btn-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #colors[green];
  }
}