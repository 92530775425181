@import "./theme.less";

.auth-container {
	height: 100vh;
	background: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 571px;
	margin: auto;
}

.ant-card-body {
	padding: 43px 92px;
}

.ant-card-bordered {
	border-radius: 5px;
}

.auth-link {
	padding: 0;
	margin: -16px 0 27px;
}

.ant-alert {
	margin-bottom: 20px;
}

@primary-color: #1DA57A;