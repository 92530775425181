.he-table {
	border: 1px solid #d4dbe3;
	border-radius: 5px;
}

.filters-list,
.filters-pagination {
	margin: 27px 0;
}

.filters-pagination {
	float: right;
}

.edit-icon {
	font-size: 20px;
}

button.expand-btn,
button.minimize-btn {
	position: absolute;
	z-index: 200;
	right: 25px;
	top: 9px;
	background: white;
	border: none;
	padding: 0;
	margin: 0;
	outline: none;
	cursor: pointer;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
	background: #c5ccca !important;
}

.minimize-table {
	height: 200px !important;
	overflow: auto !important;
}

.expand-table {
	overflow: none !important;
}
